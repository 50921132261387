<script setup lang="ts">
import { storeToRefs } from 'pinia'
import { useNavStore } from '~~/stores/nav.store'

const { $siteConfig } = useNuxtApp()

const { showNav: navIsOpen } = storeToRefs(useNavStore())
</script>

<template lang="pug">
nav(
  class="fixed z-10 -translate-x-[100%] transition-transform duration-500 ease-out p-36 pt-120 bg-grey-100 inset-0 w-screen h-screen primary-nav flex flex-col lg:flex-row lg:translate-x-0 lg:items-center lg:justify-center gap-18 md:gap-24 xl:gap-48 lg:p-0 lg:static lg:inset-none lg:w-auto lg:h-auto lg:bg-transparent"
  :class="{ '!translate-x-0': navIsOpen }"
)
  StoryblokComponent(
    v-for="blok in $siteConfig.header_nav.content.items"
    :key="blok._uid"
    :blok="{ ...blok }"
  )
</template>

<style lang="postcss">
nav.primary-nav {
  a {
    @apply text-blue text-md font-medium pt-12 pb-7 relative
      lg:pt-6 lg:text-base
      transition-colors duration-500
      after:absolute after:top-full after:left-0 after:w-36 after:lg:w-full after:h-3 after:bg-transparent after:transition-all after:duration-500 after:ease-out after:scale-x-0 after:opacity-0 after:lg:left-1/2 after:lg:-translate-x-1/2;

    &:hover,
    &:active {
      @apply text-blue-dark after:bg-blue-light after:scale-x-100 after:opacity-100;
    }

    &.router-link-exact-active {
      @apply text-blue-dark after:bg-highlight after:scale-x-100 after:opacity-100;
    }
  }
}
</style>
