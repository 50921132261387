<script setup lang="ts">
import { storeToRefs } from 'pinia'
import { useNavStore } from '~~/stores/nav.store'

const { showNav: active } = storeToRefs(useNavStore())
const { toggleNav } = useNavStore()
</script>

<template lang="pug">
a(
  role="button"
  class="hamburger p-6 pb-9"
  :class="{ isActive: active }"
  @click.prevent="toggleNav()"
)
  svg(
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 18 18"
    width="30px"
    height="30px"
    class="w-30 h-30"
  )
    path(
      fill="none"
      stroke="#21497A"
      d="M3 6h12"
      class="top"
    )
    path(
      fill="none"
      stroke="#21497A"
      d="M3 12h12"
      class="btm"
    )
</template>

<style scoped lang="pcss">
.hamburger {
  @apply cursor-pointer inline-block relative z-20;

  svg {
    path {
      @apply stroke-[1.25] origin-[50%_50%];

      stroke-linecap: round;
      transition: opacity 0.2s, transform 0.4s cubic-bezier(0, 0.3, 0.6, 1);

      &.btm {
        transform: scaleX(0.75) translateX(-12.5%);
      }
    }
  }

  &.isActive {
    .top {
      transform: rotate(225deg) translateY(20%) translateX(-5%);
    }
    .btm {
      transform: rotate(135deg) translateY(-20%) translateX(-2.5%) scaleX(1);
    }
  }
}
</style>
