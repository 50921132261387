<script setup lang="ts">
const { $siteConfig } = useNuxtApp()
const logo = computed(() => {
  return $siteConfig.value?.logo || ''
})
</script>

<template lang="pug">
header#siteHeader(class="fixed top-0 left-0 bg-grey-100 z-50 w-screen")
  div(
    class="flex justify-between items-center w-full px-18 pt-18 pb-9 md:pt-24 lg:px-24 md:pb-18"
  )
    NavigationMenuButton(class="lg:!hidden")

    div(class="brand pl-12 mr-auto lg:pl-0 lg:mr-0" v-if="logo && logo?.filename")
      nuxt-link(
        to="/"
        aria-label="Go back to the site homepage"
      )
        img(
          :src="logo.filename"
          :alt="logo.alt"
          width="160"
          height="40"
        )
    NavigationMenu(
      role="navigation"
      aria-label="Main"
    )
    NavigationMenuSecondary(
      role="navigation"
      aria-label="Secondary"
    )
</template>
<style lang="postcss" scoped>
.brand {
  @apply w-[139px] h-auto md:w-auto;
}
</style>
