<script setup lang="ts">
const { $siteConfig } = useNuxtApp()
</script>

<template lang="pug">
nav(
  class="nav-secondary flex items-center gap-9 md:gap-18 xl:gap-24 justify-end"
  v-if="$siteConfig?.header_buttons"
)
  StoryblokComponent(
    v-for="blok in $siteConfig.header_buttons"
    :key="blok._uid"
    :blok="{ ...blok }"
  )
</template>
<style lang="postcss">
.nav-secondary {
  a.button {
    @apply !text-[0.625rem] px-0 sm:px-7 font-medium md:!text-base flex-col gap-0 py-3 m-0 md:flex-row md:gap-6;

    > span:first-of-type {
      @apply order-2 md:-order-1;
    }
    &:hover {
      @apply bg-grey-200 text-blue-dark;
    }
  }

  a.social-link {
    @apply hidden sm:block;
  }
}
</style>
