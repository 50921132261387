import type { SiteConfig } from '~~/types/storyblok/contentTypes'

/**
 * This plugin provides the Storyblok site config to the app.
 *
 * Config is auto loaded from Storyblok on app load.
 * Access the config via `const { $siteConfig } = useNuxtApp()` in any component.
 * Or directly as `$siteConfig` in any template.
 */
export default defineNuxtPlugin(async (NuxtApp) => {
  const siteConfig = await getConfig()
  return {
    provide: {
      siteConfig,
    },
  }
})

const getConfig = async () => {
  const storyblokApiInstance = useStoryblokApi()
  const res = await storyblokApiInstance.get(`cdn/stories/config`, {
    resolve_relations: [
      'ContentTypeSiteConfig.header_nav',
      'ContentTypeSiteConfig.footer_nav',
    ],
  })
  return ref(res.data.story.content as SiteConfig)
}
