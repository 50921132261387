import ______node_modules__64pinia_nuxt_dist_runtime_plugin_vue3_mjs_xKuw1km5sV from "/codebuild/output/src1286570038/src/anchorage/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import _nuxt_components_plugin_mjs_KR1HBZs4kY from "/codebuild/output/src1286570038/src/anchorage/apps/frontend/.nuxt/components.plugin.mjs";
import ______node_modules_nuxt_dist_head_runtime_lib_vueuse_head_plugin_mjs_M06Spr4tXC from "/codebuild/output/src1286570038/src/anchorage/node_modules/nuxt/dist/head/runtime/lib/vueuse-head.plugin.mjs";
import ______node_modules_nuxt_dist_pages_runtime_plugins_router_mjs_W6YBFW4kOt from "/codebuild/output/src1286570038/src/anchorage/node_modules/nuxt/dist/pages/runtime/plugins/router.mjs";
import ______node_modules_nuxt_dist_pages_runtime_plugins_prefetch_client_mjs_23x09WqdPX from "/codebuild/output/src1286570038/src/anchorage/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.mjs";
import ______node_modules__64nuxt_image_edge_dist_runtime_plugin_mjs_5BYH5J5kNf from "/codebuild/output/src1286570038/src/anchorage/node_modules/@nuxt/image-edge/dist/runtime/plugin.mjs";
import ______node_modules__64storyblok_nuxt_dist_runtime_plugin_mjs_piAzjeEv9X from "/codebuild/output/src1286570038/src/anchorage/node_modules/@storyblok/nuxt/dist/runtime/plugin.mjs";
import ______node_modules_nuxt_dist_app_plugins_payload_client_mjs_DOufholzj3 from "/codebuild/output/src1286570038/src/anchorage/node_modules/nuxt/dist/app/plugins/payload.client.mjs";
import plugins_siteConfigPlugin_ts_DrIWOyCfUG from "/codebuild/output/src1286570038/src/anchorage/apps/frontend/plugins/siteConfigPlugin.ts";
export default [
  ______node_modules__64pinia_nuxt_dist_runtime_plugin_vue3_mjs_xKuw1km5sV,
  _nuxt_components_plugin_mjs_KR1HBZs4kY,
  ______node_modules_nuxt_dist_head_runtime_lib_vueuse_head_plugin_mjs_M06Spr4tXC,
  ______node_modules_nuxt_dist_pages_runtime_plugins_router_mjs_W6YBFW4kOt,
  ______node_modules_nuxt_dist_pages_runtime_plugins_prefetch_client_mjs_23x09WqdPX,
  ______node_modules__64nuxt_image_edge_dist_runtime_plugin_mjs_5BYH5J5kNf,
  ______node_modules__64storyblok_nuxt_dist_runtime_plugin_mjs_piAzjeEv9X,
  ______node_modules_nuxt_dist_app_plugins_payload_client_mjs_DOufholzj3,
  plugins_siteConfigPlugin_ts_DrIWOyCfUG
]