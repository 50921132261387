import { defineAsyncComponent } from 'vue'
import { defineNuxtPlugin } from '#app'

const components = {
  BlokArticleList: defineAsyncComponent(() => import("/codebuild/output/src1286570038/src/anchorage/apps/frontend/storyblok/Blok/BlokArticleList.vue" /* webpackChunkName: "components/blok-article-list" */).then(c => c.default || c)),
  BlokArticleShowcase: defineAsyncComponent(() => import("/codebuild/output/src1286570038/src/anchorage/apps/frontend/storyblok/Blok/BlokArticleShowcase.vue" /* webpackChunkName: "components/blok-article-showcase" */).then(c => c.default || c)),
  BlokColumns: defineAsyncComponent(() => import("/codebuild/output/src1286570038/src/anchorage/apps/frontend/storyblok/Blok/BlokColumns.vue" /* webpackChunkName: "components/blok-columns" */).then(c => c.default || c)),
  BlokContactForm: defineAsyncComponent(() => import("/codebuild/output/src1286570038/src/anchorage/apps/frontend/storyblok/Blok/BlokContactForm.vue" /* webpackChunkName: "components/blok-contact-form" */).then(c => c.default || c)),
  BlokGrid: defineAsyncComponent(() => import("/codebuild/output/src1286570038/src/anchorage/apps/frontend/storyblok/Blok/BlokGrid.vue" /* webpackChunkName: "components/blok-grid" */).then(c => c.default || c)),
  BlokGridCard: defineAsyncComponent(() => import("/codebuild/output/src1286570038/src/anchorage/apps/frontend/storyblok/Blok/BlokGridCard.vue" /* webpackChunkName: "components/blok-grid-card" */).then(c => c.default || c)),
  BlokHero: defineAsyncComponent(() => import("/codebuild/output/src1286570038/src/anchorage/apps/frontend/storyblok/Blok/BlokHero.vue" /* webpackChunkName: "components/blok-hero" */).then(c => c.default || c)),
  BlokMedia: defineAsyncComponent(() => import("/codebuild/output/src1286570038/src/anchorage/apps/frontend/storyblok/Blok/BlokMedia.vue" /* webpackChunkName: "components/blok-media" */).then(c => c.default || c)),
  BlokPortfolioItem: defineAsyncComponent(() => import("/codebuild/output/src1286570038/src/anchorage/apps/frontend/storyblok/Blok/BlokPortfolioItem.vue" /* webpackChunkName: "components/blok-portfolio-item" */).then(c => c.default || c)),
  BlokPortfolioList: defineAsyncComponent(() => import("/codebuild/output/src1286570038/src/anchorage/apps/frontend/storyblok/Blok/BlokPortfolioList.vue" /* webpackChunkName: "components/blok-portfolio-list" */).then(c => c.default || c)),
  BlokRichText: defineAsyncComponent(() => import("/codebuild/output/src1286570038/src/anchorage/apps/frontend/storyblok/Blok/BlokRichText.vue" /* webpackChunkName: "components/blok-rich-text" */).then(c => c.default || c)),
  BlokSection: defineAsyncComponent(() => import("/codebuild/output/src1286570038/src/anchorage/apps/frontend/storyblok/Blok/BlokSection.vue" /* webpackChunkName: "components/blok-section" */).then(c => c.default || c)),
  BlokSectionIntroduction: defineAsyncComponent(() => import("/codebuild/output/src1286570038/src/anchorage/apps/frontend/storyblok/Blok/BlokSectionIntroduction.vue" /* webpackChunkName: "components/blok-section-introduction" */).then(c => c.default || c)),
  BlokTeamList: defineAsyncComponent(() => import("/codebuild/output/src1286570038/src/anchorage/apps/frontend/storyblok/Blok/BlokTeamList.vue" /* webpackChunkName: "components/blok-team-list" */).then(c => c.default || c)),
  MediaImage: defineAsyncComponent(() => import("/codebuild/output/src1286570038/src/anchorage/apps/frontend/storyblok/Media/MediaImage.vue" /* webpackChunkName: "components/media-image" */).then(c => c.default || c)),
  MediaVideo: defineAsyncComponent(() => import("/codebuild/output/src1286570038/src/anchorage/apps/frontend/storyblok/Media/MediaVideo.vue" /* webpackChunkName: "components/media-video" */).then(c => c.default || c)),
  NavItem: defineAsyncComponent(() => import("/codebuild/output/src1286570038/src/anchorage/apps/frontend/storyblok/Nav/NavItem.vue" /* webpackChunkName: "components/nav-item" */).then(c => c.default || c)),
  NavMenu: defineAsyncComponent(() => import("/codebuild/output/src1286570038/src/anchorage/apps/frontend/storyblok/Nav/NavMenu.vue" /* webpackChunkName: "components/nav-menu" */).then(c => c.default || c)),
  UiButton: defineAsyncComponent(() => import("/codebuild/output/src1286570038/src/anchorage/apps/frontend/storyblok/Ui/UiButton.vue" /* webpackChunkName: "components/ui-button" */).then(c => c.default || c)),
  UiSocialLink: defineAsyncComponent(() => import("/codebuild/output/src1286570038/src/anchorage/apps/frontend/storyblok/Ui/UiSocialLink.vue" /* webpackChunkName: "components/ui-social-link" */).then(c => c.default || c)),
  ContentTypeArticle: defineAsyncComponent(() => import("/codebuild/output/src1286570038/src/anchorage/apps/frontend/storyblok/contentTypes/ContentTypeArticle.vue" /* webpackChunkName: "components/content-type-article" */).then(c => c.default || c)),
  ContentTypePage: defineAsyncComponent(() => import("/codebuild/output/src1286570038/src/anchorage/apps/frontend/storyblok/contentTypes/ContentTypePage.vue" /* webpackChunkName: "components/content-type-page" */).then(c => c.default || c)),
  ContentTypePortfolio: defineAsyncComponent(() => import("/codebuild/output/src1286570038/src/anchorage/apps/frontend/storyblok/contentTypes/ContentTypePortfolio.vue" /* webpackChunkName: "components/content-type-portfolio" */).then(c => c.default || c)),
  ContentTypeSiteConfig: defineAsyncComponent(() => import("/codebuild/output/src1286570038/src/anchorage/apps/frontend/storyblok/contentTypes/ContentTypeSiteConfig.vue" /* webpackChunkName: "components/content-type-site-config" */).then(c => c.default || c)),
  ContentTypeTeam: defineAsyncComponent(() => import("/codebuild/output/src1286570038/src/anchorage/apps/frontend/storyblok/contentTypes/ContentTypeTeam.vue" /* webpackChunkName: "components/content-type-team" */).then(c => c.default || c))
}

export default defineNuxtPlugin(nuxtApp => {
  for (const name in components) {
    nuxtApp.vueApp.component(name, components[name])
    nuxtApp.vueApp.component('Lazy' + name, components[name])
  }
})
