
import * as storyblokRuntime$0y9sQH8fP8 from '/codebuild/output/src1286570038/src/anchorage/node_modules/@nuxt/image-edge/dist/runtime/providers/storyblok'
import * as ipxRuntime$ms6GWdjq2c from '/codebuild/output/src1286570038/src/anchorage/node_modules/@nuxt/image-edge/dist/runtime/providers/ipx'

export const imageOptions = {
  "screens": {
    "xs": 320,
    "sm": 640,
    "md": 768,
    "lg": 1024,
    "xl": 1280,
    "xxl": 1536,
    "2xl": 1536
  },
  "presets": {},
  "provider": "ipx",
  "domains": [],
  "alias": {}
}

imageOptions.providers = {
  ['storyblok']: { provider: storyblokRuntime$0y9sQH8fP8, defaults: {"baseURL":"https://a-us.storyblok.com"} },
  ['ipx']: { provider: ipxRuntime$ms6GWdjq2c, defaults: {} }
}
        