<template lang="pug">
main
  PageHeader
  NuxtPage
  PageFooter
</template>

<script lang="ts" setup>
//https://github.com/storyblok/storyblok-js-client#fetch-use-polyfill-if-needed---version-5
import 'isomorphic-fetch'

const { $siteConfig } = useNuxtApp()
const SEO = useRuntimeConfig().public.SEO

const title = $siteConfig.value.seo.title || SEO.TITLE

useHead({
  titleTemplate: (titleChunk) => {
    return titleChunk ? `${titleChunk} - ${title}` : title
  },
})
</script>
