import { defineStore } from 'pinia'
import { useToggle } from '@vueuse/core'

export const useNavStore = defineStore('nav', () => {
  const [showNav, toggleNav] = useToggle(false)

  const route = useRoute()
  watch(route, () => {
    showNav.value = false
  })

  return {
    showNav,
    toggleNav,
  }
})
