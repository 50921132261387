<script lang="ts" setup>
const { $siteConfig } = useNuxtApp()

// const contactRichText = computed(() => {
//   return $siteConfig.value.contact
//     ? renderRichText($siteConfig.value.contact)
//     : ''
// })
</script>

<template lang="pug">
footer#siteFooter(
  v-editable="$siteConfig"
  v-if="$siteConfig"
  class="bg-grey-100 px-18 md:px-24 xl:px-48 py-72 lg:py-120 border-t border-grey-250"
)
  div(class="container flex flex-col gap-36")
    div(
      class="w-full text-base border-t-1 border-grey-300 md:pb-36 flex flex-col md:grid md:grid-cols-12 gap-36 md:gap-18 lg:gap-36"
    )
      div(class="md:col-span-6 lg:col-span-4 grid grid-cols-4 gap-18 md:gap-36")
        StoryblokComponent(
          v-if="$siteConfig?.footer_nav.length"
          v-for="menu in $siteConfig.footer_nav"
          :blok="menu.content"
          class="col-span-2"
        )

      div(class="md:col-span-6 lg:col-span-5 lg:col-start-8")
        div(class="w-full pt-6 text-sm text-grey-400 w-full flex flex-col gap-3")
          p {{ $siteConfig?.footer_statement }}
          p(class="mb-0") Copyright &copy; {{ new Date().getFullYear() }} {{ $siteConfig?.site_title }}
          p(class="mb-0") #[a(href="https://grade.net.au" rel="external") Made by #[strong Grade]].
</template>

<style lang="postcss">
footer#siteFooter {
  nav {
    @apply flex flex-col gap-3;
    a {
      @apply font-medium py-6;
    }
  }
}
</style>
